<template>
  <div>
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-60 md-size-50"
      ></div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-40 md-size-50"
      >
        <div
          class="crud-add-button"
          v-if="$store.getters.getPermission('devices_create')"
        >
          <div>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="material-icons"
                  data-v-step="3"
                  v-bind="attrs"
                  v-on="on"
                >
                  add_circle
                </span>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in items"
                  :key="index"
                  :to="item.link"
                  link
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
    <datatables-wrapper
      @optionsChanged="getItems"
      @updateTable="getItems"
      :loading="loading"
      :data="data"
      :headers="headers"
      filterType="simple"
      :parameters="['deviceAlias', 'deviceIdentifier']"
      @rowClick="rowClick"
      :parentSlots="parentSlots"
      :aliases="{ 'alias': 'deviceAlias', 'identifier': 'deviceIdentifier'}"
    >
      <template v-slot:timestamp="{ item }">
        {{ item.lastSuccessfulUpdate ? item.lastSuccessfulUpdate : $t("device.neverSeen") }}
      </template>
      <template v-slot:online="{ item }">
        <v-chip
          :color="item.online ? 'success' : 'error'"
          :text-color="'white'"
          small
          >
        {{ item.online ? 'Online' : 'Offline' }}
        </v-chip>
      </template>
    </datatables-wrapper>
  </div>
</template>

<script>
import DatatablesWrapper from "@/components/Datatables/DatatablesWrapper.vue";
export default {
  name: "devices-crud",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  components: {
    DatatablesWrapper,
  },
  data() {
    return {
      data: {},
      loading: false,
      items: [
        { title: "Crear desde cero", link: { name: "deviceCreate" } },
        {
          title: "Crear desde template",
          link: { name: "createDeviceFromTemplate" },
        },
      ],
      headers: [
        {
          text: "Identificador",
          align: "start",
          sortable: false,
          value: "identifier",
        },
        { text: "Alias", value: "alias", sortable: true },
        { text: "Última vez visto", value: "timestamp" },
        { text: "Online", value: "online", sortable: false },
      ],
      parentSlots: [{ name: "timestamp", slotName: "item.timestamp" }, { name: "online", slotName: "item.online" }],
    };
  },
  methods: {
    getItems(options) {
      this.loading = true;
      this.$store
        .dispatch("device/getDevices", options)
        .then((data) => {
          this.data = data;
        })
        .finally(() => (this.loading = false));
    },
    rowClick(event) {
      this.$router.push({
        name: "deviceMonitoring",
        params: { device: event.id },
      });
    },
  },
  mounted() {
    this.getItems({ page: 1, itemsPerPage: 10 });
  },
};
</script>