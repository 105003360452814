<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header :data-background-color="getTheme">
          <h4 class="title">{{ $t("device.deviceTableTitle") }}</h4>
        </md-card-header>
        <md-card-content>
          <devices-crud :table-header-color="getTheme"></devices-crud>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import DevicesCrud from "../../components/Devices/DevicesCrud";
import { mapGetters } from "vuex";
export default {
  components: {
    DevicesCrud,
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
};
</script>