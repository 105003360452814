<template>
  <div class="datatables-filter-input">
    <v-text-field
      prepend-icon="mdi-magnify"
      v-on="{
        ...$listeners,
        input: (event) => generateFilter(event),
      }"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    parameters: {
      type: Array, //mismos headers que se le pasan al datatables, sólo se usa para simpleWithSelector o para multiple
    },
    filterType: {
      type: String,
      default: "simple", // ['simple', 'simpleWithSelector', 'multiple'] Por ahora sólo se ha desarrollado para 'simple'
    },
    parameter: {
      type: String, // se puede utilizar cuando se usa el filterType simple, es para filtrar usando un sólo parámetro y no todos los posibles
    },
  },
  inheritAttrs: false,
  methods: {
    generateFilter(value) {
      let filterEmit = [];
      this.parameters.forEach((parameter) => {
        filterEmit.push([parameter, value]);
      });
      console.log(`filter emit ${filterEmit}`);
      if (value === "") filterEmit = [];
      this.emitEvent(filterEmit, value);
    },
    emitEvent(filterEmit, value) {
      //TODO armar paquete de filtro que la api pueda entender antes de emitirlo
      this.$emit("input", this.parameters.length > 0 ? filterEmit : value);
    },
  },
};
</script>

<style scoped lang="sass">
.datatables-filter-input
  width: 100%
  @media screen and (min-width: 768px)
    width: 80%
    @media screen and (min-width: 1200px)
      width: 60%
    @media screen and (min-width: 1600px)
      width: 40%
</style>