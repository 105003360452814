<template>
  <div>
    <div class="row ml-3">
      <datatables-filter
        v-model="filter"
        :parameters="parameters"
        :parameter="parameter"
        :filterType="filterType"
      />
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      class="elevation-0"
      :item-class="rowStyle"
      @click:row="rowClick"
    >
      <template
        v-for="slotName in parentSlots"
        v-slot:[slotName.slotName]="{ item }"
      >
        <slot :name="slotName.name" :item="item"></slot>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DatatablesFilter from "./DatatablesFilter.vue";

export default {
  components: { DatatablesFilter },
  props: {
    defaultOptions: {
      type: Object,
      default: () => ({
        multiSort: true,
      }),
    },
    headers: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({
        data: [],
        total: 0,
      }),
    },
    parameters: {
      type: Array, //mismos headers que se le pasan al datatables, sólo se usa para simpleWithSelector o para multiple
    },
    filterType: {
      type: String,
      default: "simple", // ['simple', 'simpleWithSelector', 'multiple'] Por ahora sólo se ha desarrollado para 'simple'
    },
    parameter: {
      type: String, // se puede utilizar cuando se usa el filterType simple, es para filtrar usando un sólo parámetro y no todos los posibles
    },
    parentSlots: {
      type: Array,
      require: false,
    },
    aliases: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    options: {},
    filter: [],
  }),
  beforeMount() {
    Object.keys(this.defaultOptions).forEach(
      (key) => (this.options[key] = this.defaultOptions[key])
    );
  },
  watch: {
    options: {
      handler() {
        this.emitOptions();
      },
      deep: true,
    },
    filter() {
      this.emitOptions();
    },
  },
  computed: {
    items() {
      return this.data?.data || []; // si this.data existe retorno el valor de this.data.data. En el caso contrario retorno un array vacío
    },
    total() {
      return this.data?.total || 0; // si this.data existe retorno el valor de this.data.total. En el caso contrario retorno 0
    },
  },
  methods: {
    emitOptions() {
      const order = this.options.sortBy.map((item, index) => [
        this.aliases[item] || item,
        this.options.sortDesc[index] ? "DESC" : "ASC",
      ]);
      const filters =
        this.filter.length > 0 ? JSON.stringify(this.filter) : this.filter;
      const newOptions = {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        order: JSON.stringify(order),
        filters,
      };

      this.$emit("optionsChanged", newOptions);
      console.log("Nueva configuración para datatables:", newOptions);
    },
    rowStyle() {
      return "row-style";
    },
    rowClick(event) {
      this.$emit("rowClick", event);
    },
    parseName(slotName) {
      let slotNameSplit = slotName.split(".");
      let name = slotNameSplit.at(-1);
      return name;
    },
  },
};
</script>
<style lang="sass">
.row-style
  cursor: pointer
</style>